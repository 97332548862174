import styled from "styled-components"

import colors from "../../utils/colors"

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 0.9rem;
  }
`

export const Input = styled.input`
  padding: 12px;
  border: 1px solid ${colors.border};
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 6px;
  resize: vertical;
  background-color: transparent;

  &:focus {
    outline: none;
    border-color: ${colors.green};
  }
`

export const Textarea = styled.textarea`
  padding: 12px;
  border: 1px solid ${colors.border};
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 6px;
  resize: vertical;
  background-color: transparent;

  &:focus {
    outline: none;
    border-color: ${colors.green};
  }
`

export const Button = styled.button`
  margin-left: auto;
  padding: 10px;
  width: 120px;
  color: ${colors.white};
  background-color: ${colors.green};
  border: 1px solid ${colors.green};
  border-radius: 2px;
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${colors.green};
    background-color: ${colors.white};
  }
`
