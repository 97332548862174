import React, { Component } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { Button, Form, Input, Textarea } from "./style"

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    message: "",
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { name, email, message } = this.state
    const subject = `[chevre.bio] Nouveau message envoyé par ${name}`

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, subject, message }),
    })
      .then(() => {
        toast.success("Email envoyé avec succès!", { autoClose: 5000 })
        return this.setState({ name: "", email: "", message: "" })
      })
      .catch(error => toast.error(error))
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Input
          placeholder="Nom - Prénom"
          type="text"
          name="name"
          value={this.state.name}
          onChange={this.handleChange}
          required
        />
        <Input
          placeholder="Adresse email"
          type="email"
          name="email"
          value={this.state.email}
          onChange={this.handleChange}
          required
        />
        <Textarea
          rows="8"
          placeholder="Message"
          type="text"
          name="message"
          value={this.state.message}
          onChange={this.handleChange}
          required
        />
        <Button type="submit">Envoyer</Button>
        <ToastContainer />
      </Form>
    )
  }
}
export default ContactForm
