import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactForm from "../components/ContactForm"

const Contact = () => (
  <Layout>
    <SEO
      title="Contactez-nous"
      description="GAEC L'abbaye d'en bas 56800 CAMPÉNÉAC"
    />
    <div style={{ marginBottom: "1.8rem" }}>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Contactez-nous
      </h1>
    </div>
    <ContactForm />
  </Layout>
)

export default Contact
